var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2 mb-4 text-h6 font-weight-black align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-tie")]),_vm._v(" Usuários "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.dialogCreate = true}}},[_vm._v(" Criar novo ")])],1),_c('Filters',{staticClass:"mb-4",attrs:{"dataFilters":require('@/assets/filters/managers.json')},on:{"apply-filter":_vm.applyFilter}}),_c('section',[_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"accent pointer-hand",attrs:{"headers":_vm.headers,"items":_vm.managers,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":0,"custom-sort":_vm.customSort,"hide-default-footer":""},on:{"dblclick:row":_vm.handleManage},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isActive ? "Ativo" : "Inativo")+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleManage(null, { item: item })}}},[_c('v-icon',[_vm._v(" mdi-open-in-app ")])],1)]}}],null,true)})],1),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.pageCount,"total-visible":10,"circle":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[(_vm.dialogEdit)?_c('EditUser',{attrs:{"selected":_vm.selected},on:{"success":function($event){return _vm.getData()},"close":function($event){_vm.dialogEdit = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialogCreate),callback:function ($$v) {_vm.dialogCreate=$$v},expression:"dialogCreate"}},[(_vm.dialogCreate)?_c('CreateUser',{on:{"success":function($event){return _vm.getData()},"close":function($event){_vm.dialogCreate = false}}}):_vm._e()],1),(_vm.loading)?_c('loader-hover'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }