<template>
  <v-container class="py-4" fluid>
    <v-row class="mx-2 mb-4 text-h6 font-weight-black align-center" no-gutters>
      <v-icon class="mr-3">mdi-account-tie</v-icon>
      Usuários

      <v-spacer />

      <v-btn color="primary" depressed small @click="dialogCreate = true">
        Criar novo
      </v-btn>
    </v-row>

    <Filters
      class="mb-4"
      :dataFilters="require('@/assets/filters/managers.json')"
      @apply-filter="applyFilter"
    />

    <section>
      <!-- table -->
      <v-card class="rounded-lg" outlined>
        <v-data-table
          class="accent pointer-hand"
          :headers="headers"
          :items="managers"
          :items-per-page="itemsPerPage"
          :mobile-breakpoint="0"
          :custom-sort="customSort"
          hide-default-footer
          @dblclick:row="handleManage"
        >
          <!-- user -->
          <template v-slot:[`item.firstName`]="{ item }">
            {{ item.firstName }} {{ item.lastName }}
          </template>

          <!-- status -->
          <template v-slot:[`item.isActive`]="{ item }">
            {{ item.isActive ? "Ativo" : "Inativo" }}
          </template>

          <!-- createdAt -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- open -->
          <template v-slot:[`item.open`]="{ item }">
            <v-btn
              class="rounded-lg"
              icon
              small
              @click="handleManage(null, { item })"
            >
              <v-icon> mdi-open-in-app </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="page"
        class="py-4"
        color="primary"
        :length="pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>

    <v-dialog v-model="dialogEdit" max-width="500" persistent>
      <EditUser
        v-if="dialogEdit"
        :selected="selected"
        @success="getData()"
        @close="dialogEdit = false"
      />
    </v-dialog>

    <v-dialog v-model="dialogCreate" max-width="500" persistent>
      <CreateUser
        v-if="dialogCreate"
        @success="getData()"
        @close="dialogCreate = false"
      />
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { getUsers } from "@/services/users";
import { displayAlert, formatDate } from "@/utils";
import Filters from "@/components/filters/Filters";
import EditUser from "@/components/users/EditUser";
import CreateUser from "@/components/users/CreateUser";

export default {
  data() {
    return {
      interval: null,
      loading: true,
      dialogEdit: false,
      dialogCreate: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      sort: "createdAt,desc",
      headers: [
        { text: "Nome", value: "firstName" },
        { text: "E-mail", value: "email" },
        { text: "Status", value: "isActive" },
        { text: "Data de registro", value: "createdAt" },
        { text: "Abrir", value: "open", align: "center", sortable: false },
      ],
      managers: [],
      filter: [],
      selected: null,
    };
  },

  components: {
    Filters,
    EditUser,
    CreateUser,
  },

  beforeMount() {
    this.getData();

    this.interval = setInterval(() => {
      if (!this.loading) this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: String(this.page - 1),
          size: this.itemsPerPage,
          sort: this.sort,
          ...this.filter,
        };

        await getUsers(payload).then((res) => {
          this.managers = res.body.content;
          this.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleManage(event, { item }) {
      this.selected = item;
      this.dialogEdit = true;
    },

    // get data with filter
    applyFilter(value) {
      this.loading = true;
      this.filter = value;
      this.page = 1;

      this.getData();
    },

    // sort table
    customSort(items, sortBy, sortDesc) {
      let value = sortBy[0];
      let sort = "";

      if (value) sort = value + "," + (sortDesc[0] ? "desc" : "asc");
      else sort = "createdAt,desc";

      if (sort !== this.sort) {
        this.sort = sort;
        this.getData();
      }

      return items;
    },

    displayAlert,

    formatDate,
  },
};
</script>