<template>
  <v-card class="rounded-lg" color="accent">
    <section class="pa-6">
      <v-card-title class="px-0 pt-0">
        Cadastrar usuário <v-spacer />
        <v-btn class="rounded-lg" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form v-model="valid" ref="form" autocomplete="off">
        <!-- nome -->
        <v-text-field
          v-model="form.firstName"
          label="Nome"
          background-color="foreground"
          :rules="rule"
          outlined
          @keydown.space.prevent
        />

        <!-- Sobrenome -->
        <v-text-field
          v-model="form.lastName"
          label="Sobrenome"
          background-color="foreground"
          :rules="rule"
          outlined
          @keydown.space.prevent
        />

        <!-- email -->
        <v-text-field
          v-model="form.email"
          label="E-mail"
          background-color="foreground"
          :rules="emailRule"
          outlined
          @keydown.space.prevent
        />

        <!-- senha -->
        <v-text-field
          v-model="form.password"
          label="Senha"
          backgroundColor="foreground"
          :type="show ? 'text' : 'password'"
          :rules="passwordRule"
          outlined
          @keydown.space.prevent
        >
          <template v-slot:append>
            <v-icon color="secondary" @click="show = !show">
              {{ show ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
            </v-icon>
          </template>
        </v-text-field>

        <!--status -->
        <v-list-item class="foreground rounded-lg mb-6">
          <v-list-item-content>
            <v-list-item-title>Status</v-list-item-title>

            <v-list-item-title>
              <span :class="`${status[form.status].color}--text`">
                {{ status[form.status].label }}
              </span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="status[form.status].color"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  small
                >
                  {{ status[form.status].label }}

                  <v-icon right> mdi-chevron-down </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in status"
                  :key="index"
                  @click="form.status = index"
                >
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-form>

      <v-row no-gutters>
        <v-spacer />

        <v-btn plain @click="close()"> Cancelar </v-btn>

        <v-btn color="primary" @click="submit()"> Confirmar </v-btn>
      </v-row>
    </section>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { createUser } from "@/services/users";
import { displayAlert, capitalize, lowerCase } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      show: false,
      status: [
        {
          value: true,
          label: "Ativo",
          color: "green",
        },
        {
          value: false,
          label: "Inativo",
          color: "red",
        },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        status: 0,
      },
    };
  },

  watch: {
    ["form.firstName"](value) {
      this.form.firstName = capitalize(value);
    },

    ["form.lastName"](value) {
      this.form.lastName = capitalize(value);
    },

    ["form.email"](value) {
      this.form.email = lowerCase(value);
    },
  },

  computed: {
    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    emailRule() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => /.+@.+/.test(v) || "Invalid e-mail",
      ];
    },

    passwordRule() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 6 || "Invalid password",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
          isActive: this.status[this.form.status].value,
        };

        await createUser(payload).then(() => {
          this.displayAlert("Administrador registrado com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style>
</style>