import { request } from "./index";

export const getUsers = (data) =>
  request(
    "get",
    `/users?page=${data.page}&size=${data.size}&sort=${data.sort}&id=${data.id}&name=${data.name}&email=${data.email}`
  );

export const createUser = (data) => request("post", "/users", data);

export const editUser = (data) => request("post", "/users/update", data);
